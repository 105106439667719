var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bank_card"},[_c('main',[(!_vm.bankCard.length)?_c('p',{staticClass:"none"},[_vm._v("暂无银行卡")]):_c('div',{staticClass:"bank_card_list"},_vm._l((_vm.bankCard),function(list){return _c('div',{key:list.bankAcctNo,staticClass:"card",style:([
          {
            background:
              list.serviceType === '1'
                ? ("url(" + (require('@/assets/images/gzyhbg.png')) + ")")
                : '',
          } ])},[_c('img',{staticClass:"logo",attrs:{"src":list.serviceType === '1'
              ? require('@/assets/images/gaungzhou_icon.png')
              : '',"alt":""}}),_c('div',{staticClass:"content"},[_c('p',{staticClass:"card_name"},[_vm._v(_vm._s(list.bankName))]),_c('p',{staticClass:"card_type"},[_vm._v("储蓄卡")]),_c('p',{staticClass:"card_num"},[_vm._v(_vm._s(_vm._f("cardNumber")(list.bankAcctNo)))])]),_c('div',{staticClass:"controls"},[_c('svg-icon',{attrs:{"icon-class":"del"}}),_c('span',[_vm._v("删除银行卡")])],1)])}),0),_c('van-button',{attrs:{"round":"","type":"info","color":"#009A4D","block":""}},[_vm._v(" 添加银行卡 ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
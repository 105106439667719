<template>
  <div class="bank_card">
    <main>
      <p class="none" v-if="!bankCard.length">暂无银行卡</p>
      <div class="bank_card_list" v-else>
        <div
          class="card"
          v-for="list in bankCard"
          :key="list.bankAcctNo"
          :style="[
            {
              background:
                list.serviceType === '1'
                  ? `url(${require('@/assets/images/gzyhbg.png')})`
                  : '',
            },
          ]"
        >
          <img
            :src="
              list.serviceType === '1'
                ? require('@/assets/images/gaungzhou_icon.png')
                : ''
            "
            alt=""
            class="logo"
          />
          <div class="content">
            <p class="card_name">{{ list.bankName }}</p>
            <p class="card_type">储蓄卡</p>
            <p class="card_num">{{ list.bankAcctNo | cardNumber }}</p>
          </div>
          <div class="controls">
            <svg-icon icon-class="del" />
            <span>删除银行卡</span>
          </div>
        </div>
      </div>
      <van-button round type="info" color="#009A4D" block>
        添加银行卡
      </van-button>
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "bank-card",
  computed: {
    ...mapState("user", ["isLogin", "bankCard"]),
  },
  data() {
    return {};
  },
  filters: {
    cardNumber(val) {
      let num = val.substr(0, 4) + "********" + val.substr(15);
      return num;
    },
  },

  created() {
    if (this.isLogin) this.$store.dispatch("user/selectSettleAcctList");

    console.log(this.bankCard);
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.bank_card {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 46px;
  main {
    flex: 1;
    background: #f5f5f5;
    padding: 15px;
    .none {
      color: #999999;
      text-align: center;
      padding: 44px 0;
    }
    .bank_card_list {
      .card {
        background-size: 100% !important;
        border-radius: 12px;
        height: 135px;
        display: flex;
        justify-content: space-between;
        color: #fff;
        padding: 16px 12px;
        margin-bottom: 12px;
        .logo {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          background: #fff;
          padding: 6px;
        }
        .content {
          .card_name {
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 8px;
          }
          .card_type {
            font-size: 12px;
            line-height: 17px;
            margin-bottom: 15px;
          }
          .card_num {
            line-height: 20px;
          }
        }
        .controls {
          display: flex;
          align-items: center;
          .svg-icon {
            color: #fff;
            margin-right: 4px;
          }
        }
      }
      .card:last-child {
        margin-bottom: 0;
      }
    }
    .van-button {
      margin-top: 65px;
    }
  }
}
</style>
